<template>
    <div
        :class="{
            markdown_editor: true,
            small: size === 'small',
            middle: size === 'middle',
            large: size === 'large',
            no_border: !border,
        }"
    >
        <!-- 设置html为false时控制台会报错，是框架内部错误，暂时不影响使用 -->
        <mavon-editor
            v-model="editorValue"
            :toolbars="toolbars"
            :subfield="mode === 'edit'"
            :defaultOpen="mode"
            :toolbarsFlag="mode !== 'preview'"
            :previewBackground="previewBackground"
            :fontSize="fontSize"
            :boxShadow="boxShadow"
            :html="false"
        ></mavon-editor>
    </div>
</template>

<script>
// https://github.com/hinesboy/mavonEditor 具体配置项请看文档
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
export default {
    name: "editor",
    components: {
        mavonEditor,
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: String,
        },
        mode: {
            type: "edit" | "preview", // 编辑模式还是预览模式
            default: "edit",
        },
        previewBackground: {
            type: String, // 预览区域背景色
            default: "#fbfbfb",
        },
        fontSize: {
            type: String, // 编辑区字体大小
            default: "12px",
        },
        size: {
            type: "small" | "middle" | "large", // 预览字体大小
            default: "small",
        },
        boxShadow: {
            type: Boolean, // 是否展示边框阴影
            default: true,
        },
        border: {
            type: Boolean, // 是否展示边框
            default: true,
        },
    },
    computed: {},
    data: () => {
        return {
            toolbars: {
                bold: true, // 粗体
                italic: true, // 斜体
                header: true, // 标题
                underline: true, // 下划线
                strikethrough: true, // 中划线
                mark: false, // 标记
                superscript: false, // 上角标
                subscript: false, // 下角标
                quote: false, // 引用
                ol: true, // 有序列表
                ul: true, // 无序列表
                link: true, // 链接
                imagelink: false, // 图片链接
                code: false, // code
                table: true, // 表格
                fullscreen: true, // 全屏编辑
                readmodel: true, // 沉浸式阅读
                htmlcode: false, // 展示html源码
                help: true, // 帮助
                /* 1.3.5 */
                undo: true, // 上一步
                redo: true, // 下一步
                trash: true, // 清空
                save: false, // 保存（触发events中的save事件）
                /* 1.4.2 */
                navigation: true, // 导航目录
                /* 2.1.8 */
                alignleft: true, // 左对齐
                aligncenter: true, // 居中
                alignright: true, // 右对齐
                /* 2.2.1 */
                subfield: true, // 单双栏模式
                preview: true, // 预览
            },
        };
    },
    computed: {
        editorValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("change", value);
            },
        },
    },
};
</script>

<style scoped lang="stylus">
.markdown_editor{
    width: 100%;
    height: 100%;
    .markdown-body{
      z-index: 1;
      height: 100%;
    }
    .v-note-wrapper {
      min-height: auto;
      min-width: auto;
    }
    &.no_border{
      .v-note-wrapper{
        border: none;
      }
    }
    &.small{
        .markdown-body{
            font-size:12px;
        }
    }
    &.middle{
        .markdown-body{
            font-size:14px;
        }
    }
    &.large{
        .markdown-body{
            font-size:16px;
        }
    }
}
.markdown-body /deep/ .v-show-content{
    padding: 8px !important;
}
</style>
